// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
// import favicon here.

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed 
    _badgerInstances: any; //accordion
  }
}

const init = () => {
  if (window.Bus) {
    window.Bus.on('emu-button:click', ({ id, toggleOn }) => {
      if('main-nav-button' === id) {
        if (toggleOn) {
          document.body.classList.add('js-disable-scroll');
        } else {
          document.body.classList.remove('js-disable-scroll');
        }
      }
    });
  }
  // checks if blog post page
  if (window.location.pathname.includes('/blog/')) {
    document.body.dataset.pageType = 'blog';
  }
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}