const _temp0 = require("./components/articleList/_articleList.scss");

const _temp1 = require("./components/footer/_footer.scss");

const _temp2 = require("./components/header/_header.scss");
const _temp3 = require("./components/header/_nav.scss");

const _temp4 = require("./components/image/_image.scss");

const _temp5 = require("./components/teaser/_teaser.scss");

module.exports = {
  "articleList":   {
    "_articleList": _temp0
  },
  "footer":   {
    "_footer": _temp1
  },
  "header":   {
    "_header": _temp2,
    "_nav": _temp3
  },
  "image":   {
    "_image": _temp4
  },
  "teaser":   {
    "_teaser": _temp5
  }
}